import type { RouteLocationRaw } from 'vue-router';

import { useRouter } from 'vue-router';

import { router as globalRouter } from '~~/router';
import { ROOT_ROUTE_NAME } from '~~/router/router.entity';

interface RouterPushOptions {
  params?: Record<string, string>;
  query?: Record<string, string>;
}

export function useNavigation(inSetup = true) {
  const router = inSetup ? useRouter() : globalRouter;
  const route = globalRouter.currentRoute;

  async function routerPushByName(key: string, options?: RouterPushOptions) {
    const { query, params } = options || {};

    const routeLocation: RouteLocationRaw = {
      name: key,
    };

    if (query) {
      routeLocation.query = query;
    }

    if (params) {
      routeLocation.params = params;
    }

    return router.push(routeLocation);
  }

  async function redirectFromLogin() {
    const redirect = route.value.query?.redirect as string;

    if (redirect) {
      router.push(redirect);
    } else {
      router.push({ name: ROOT_ROUTE_NAME });
    }
  }

  return {
    routerPushByName,
    redirectFromLogin,
  };
}
