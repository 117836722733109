/* eslint-disable ts/unified-signatures */

import { i18n } from './locale.setup';

interface I18nGlobalTranslation {
  (key: string): string;
  (key: string, locale: string): string;
  (key: string, locale: string, list: Array<unknown>): string;
  (key: string, locale: string, named: Record<string, unknown>): string;
  (key: string, list: Array<unknown>): string;
  (key: string, named: Record<string, unknown>): string;
}

type I18nTranslationRestParameters = [string, any];

export function useI18n(namespace?: string): {
  $t: I18nGlobalTranslation;
} {
  const normalFn = {
    $t: (key: string) => {
      return getKey({ key, namespace });
    },
  };

  if (!i18n) {
    return normalFn;
  }

  const { t, ...methods } = i18n.global;

  const tFn: I18nGlobalTranslation = (key: string, ...arg: Array<any>) => {
    if (!key) {
      return '';
    }
    if (!key.includes('.') && !namespace) {
      return key;
    }

    return (t as (arg0: string, ...arg: I18nTranslationRestParameters) => string)(
      getKey({ key, namespace }),
      ...(arg as I18nTranslationRestParameters),
    );
  };

  return {
    ...methods,
    $t: tFn,
  };
}

function getKey({ key, namespace }: { key: string; namespace?: string }) {
  if (!namespace) {
    return key;
  }
  if (key.startsWith(namespace)) {
    return key;
  }
  return `${namespace}.${key}`;
}
