import type { SkyCombineItem, SkyCombineProduct, SkyParentProduct, SkyProduct } from '@vinicunca/kuli-entity';
import type { UpdateData } from 'firebase/firestore';

import { ACTIVE_STATUSES, FIRESTORE_COLLECTION, PRODUCT_STATUS } from '@vinicunca/kuli-entity';
import { Timestamp, getCountFromServer, getDocs, increment, query, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import type { FirestoreData } from '~~/api/repositories/repository.types';

import { flavourRepo, parentProductRepo } from '~~/api/repositories';
import { BaseRepository } from '~~/api/repositories/repository-factory';
import { useProductStore } from '~~/modules/product/product.store';
import { isProductNotAvailable } from '~~/modules/product/product.util';

export class ProductRepository extends BaseRepository<SkyProduct> {
  constructor() {
    super(FIRESTORE_COLLECTION.PRODUCTS);
  }

  async addProduct(data: FirestoreData<SkyProduct>) {
    const id = uuidv4();

    await this.addDocument({
      data,
      id,
    });

    return id;
  }

  editProduct(editionInput: UpdateData<SkyProduct>) {
    const { id, ...data } = editionInput;
    return this.updateDocument({
      id: id as string,
      data,
    },
    );
  }

  returnItem(input: SkyProduct) {
    this.editProduct({
      id: input.id,
      status: PRODUCT_STATUS.RETOUR,
    });

    parentProductRepo.editParentProduct({
      id: input.parentRef.id,
      initialGrams: increment(input.initialGrams),
    });

    flavourRepo.decreaseChildStockByOne(input.flavourRef.id, input.size.id);
  }

  async getTotalBySize(flavourId: string) {
    const productStore = useProductStore();

    const productSizes: Record<string, number> = {};
    const flavourRefFilter = flavourRepo.getDocRef(flavourId);

    // We slice from one since we don't need to get the parent size
    const queryPromises = Object.keys(productStore.sizes)
      .slice(1).map((sizeKey) => {
        const query_ = query(
          this.collectionRef,
          where('flavourRef', '==', flavourRefFilter),
          where('size.id', '==', sizeKey),
          where('status', 'in', ACTIVE_STATUSES),
        );

        return getCountFromServer(query_).then((result) => {
          const count = result.data().count;

          if (count > 0) {
            productSizes[sizeKey] = result.data().count;
          }
        });
      });

    await Promise.all(queryPromises);

    return productSizes;
  }

  async scanCombineProduct(inputCode: string): Promise<SkyCombineItem | null> {
    const parentData = await parentProductRepo.getDocument(inputCode);

    if (!parentData) {
      return this.getWithFlavour(inputCode);
    }

    const flavour = await flavourRepo.getDocument(parentData.flavourRef.id);

    return {
      product: transformCombineProduct(parentData),
      flavour: flavour!,
      isItemNotAvailable: isProductNotAvailable(parentData.status),
      isParent: true,
    };
  }

  async getWithFlavour(inputCode: string): Promise<SkyCombineItem | null> {
    const productData = await this.getDocument(inputCode);

    if (!productData) {
      return null;
    }

    const flavour = await flavourRepo.getDocument(productData.flavourRef.id);

    return {
      product: transformCombineProduct(productData),
      flavour: flavour!,
      isItemNotAvailable: isProductNotAvailable(productData.status),
      isParent: false,
    };
  }

  async getProcessedProductInPeriod(filterInput: number) {
    const previousYear = filterInput - 1;

    // Create an array to store counts for each month
    const monthCounts = Array(12).fill(0);

    const q = query(
      this.collectionRef,
      where('createdAt', '>=', Timestamp.fromDate(new Date(previousYear, 0, 1))), // Start of previous year
      where('createdAt', '<', Timestamp.fromDate(new Date(filterInput, 0, 1))), // Start of current year
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const createdAt = doc.data().createdAt.toDate();
      const month = createdAt.getMonth();
      monthCounts[month]++;
    });
    return monthCounts.map((count) => ({
      count,
    }));
  }
}

function transformCombineProduct(product: SkyParentProduct | SkyProduct): SkyCombineProduct {
  const restGrams = isParent(product) ? product.restGrams : product.initialGrams;
  const size = isParent(product) ? '1' : product.size.id;

  return {
    id: product.id,
    initialGrams: product.initialGrams,
    restGrams,
    status: product.status,
    createdAt: product.createdAt,
    size,
  };
}

function isParent(product: any): product is SkyParentProduct {
  return ('restGrams' in product);
}
