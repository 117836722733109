import type { SkyVendor } from '@vinicunca/kuli-entity';

import { FIRESTORE_COLLECTION } from '@vinicunca/kuli-entity';
import { addDoc } from 'firebase/firestore';

import { BaseRepository } from '~~/api/repositories/repository-factory';

import type { FirestoreData } from '../repository.types';

export class VendorRepository extends BaseRepository<SkyVendor> {
  constructor() {
    super(FIRESTORE_COLLECTION.VENDORS);
  }

  getAll() {
    return this.executeQuery(this.collectionRef);
  }

  async addVendor(data: FirestoreData<SkyVendor>) {
    return addDoc(this.collectionRef, data);
  }
}
