export enum AUTH_ROLE {
  ADMIN = 'admin',
  ROOT = 'root',
  USER = 'user',
}

export interface AuthUser {
  // These values are fetched from firebase auth
  id: string;
  email: string;
  photoURL?: string;
  // These values are fetched from firestore
  firstName?: string;
  lastName?: string;
  roles?: Array<AUTH_ROLE>;
  homePath?: string;
}

export interface CreateUserRequest extends Omit<AuthUser, 'id'> {
  password: string;
};

export interface CreateUserResponse {
  uid: string;
}

export interface SkyRole {
  id: string;
  label: string;
}
