import { extend } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

import { setDayjsLocale } from '~~/locales/locale.dayjs';

export function setupDayjs() {
  extend(localeData);

  setDayjsLocale();
}
