import type { RouteRecordRaw } from 'vue-router';

export function sortRoutesByOrder(routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> {
  routes.sort(routeSorterByOrder);

  routes.forEach(sortChildrenRoutes);

  return routes;
}

function sortChildrenRoutes(route: RouteRecordRaw): RouteRecordRaw {
  if (route.children?.length) {
    route.children.sort(routeSorterByOrder);

    route.children.forEach(sortChildrenRoutes);
  }

  return route;
}

function routeSorterByOrder(next: RouteRecordRaw, prev: RouteRecordRaw): number {
  return (Number(next.meta?.order) || 0) - (Number(prev.meta?.order) || 0);
}

/**
 * Get cache route names
 */
export function getCacheRouteNames(routes: Array<RouteRecordRaw>) {
  const cacheNames: Array<string> = [];

  routes.forEach((route) => {
    // only get last two level route, which has component
    route.children?.forEach((child) => {
      if (child.component && child.meta?.keepAlive) {
        cacheNames.push(child.name as string);
      }
    });
  });

  return cacheNames;
}

/**
 * Filter auth routes by roles
 */
export function filterAuthRoutesByRoles(
  { routes, roles }: { roles: Array<string>; routes: Array<RouteRecordRaw> },
) {
  return routes.flatMap((route) => filterRouteByRoles({ route, roles }));
}

/**
 * Filter single auth route by roles
 */
function filterRouteByRoles(
  { route, roles }: { roles: Array<string>; route: RouteRecordRaw },
) {
  const routeRoles = (route.meta && route.meta.roles) || [];

  // if the route's "roles" is empty, then it is allowed to access
  const isEmptyRoles = !routeRoles.length;

  // if the user's role is included in the route's "roles", then it is allowed to access
  const hasPermission = routeRoles.some((role) => roles.includes(role));

  const filterRoute = { ...route };

  if (filterRoute.children?.length) {
    filterRoute.children = filterRoute.children.flatMap((item) => filterRouteByRoles({ route: item, roles }));
  }

  return hasPermission || isEmptyRoles ? [filterRoute] : [];
}
