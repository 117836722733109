import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT } from '~~/router/router.entity';

const stockRoute: RouteRecordRaw = {
  path: '/stock',
  name: 'StockRoot',
  component: LAYOUT_DEFAULT,
  meta: {
    order: 4,
    title: 'Stock',
    localIcon: 'stock-black',
    i18nKey: 'routes.stock',
  },
  children: [
    {
      path: '',
      name: '/stock',
      component: () => import('~~/pages/stock.vue'),
      meta: {
        title: 'Stock',
        localIcon: 'stock-black',

        hideInMenu: true,
      },
    },
    {
      path: ':flavourId',
      name: '/stock/:flavourId',
      component: () => import('~~/pages/stock-detail.vue'),
      meta: {
        title: 'Stock Detail',
        hideInMenu: true,
        localIcon: 'stock-black',

      },
    },
  ],
};

export default stockRoute;
