export enum FIRESTORE_COLLECTION {
  ACTIVITY_TYPES = 'activityTypes',
  FLAVOURS = 'flavours',
  MASTER_ACTIVITY_LOG = 'masterActivityLogs',
  PARENT_PRODUCTS = 'parentProducts',
  PRODUCTS = 'products',
  QRSTORE = 'qrStore',
  ROLES = 'roles',
  SIZES = 'sizes',
  STATUSES = 'statuses',
  STOCKRECOUNT = 'stockRecount',
  USERS = 'users',
  VENDORS = 'vendors',
}
