export enum PAGES {
  // basic home path
  CORE_HOME = '/dashboard',
  // basic login path
  CORE_LOCK = '/lock',
  // basic login path
  CORE_LOGIN = '/auth/login',
  // error log page path
  ERROR_LOG_PAGE = '/error-log/list',
  // error page path
  ERROR_PAGE = '/exception',
}

export const ROOT_ROUTE_NAME = 'Root';

export const NOT_FOUND_ROUTE_NAME = 'RouteNotFound';

export const AUTH_ROUTE_NAME = 'RouteAuth';

export const EXCEPTION_COMPONENT = () => import('~~/pages/exception.vue');

export const LAYOUT_DEFAULT = () => import('~~/layouts/default.vue');
export const LAYOUT_AUTH = () => import('~~/layouts/auth.vue');
