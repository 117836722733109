import type { Component } from 'vue';

import { h } from 'vue';

/**
 * Svg icon render hook
 */
export function useSvgIconRender(SvgIcon: Component) {
  interface IconConfig {
    /** Icon color */
    color?: string;
    /** Icon size */
    fontSize?: number;
    /** Iconify icon name */
    icon?: string;
    /** Local icon name */
    localIcon?: string;
  }

  type IconStyle = Partial<Pick<CSSStyleDeclaration, 'color' | 'fontSize'>>;

  /**
   * Svg icon VNode
   */
  const SvgIconVNode = (config: IconConfig) => {
    const { color, fontSize, icon, localIcon } = config;

    const style: IconStyle = {};

    if (color) {
      style.color = color;
    }
    if (fontSize) {
      style.fontSize = `${fontSize}px`;
    }

    if (!icon && !localIcon) {
      return undefined;
    }

    return () => h(SvgIcon, { icon, localIcon, style });
  };

  return {
    SvgIconVNode,
  };
}
