import { PRODUCT_STATUS } from '@vinicunca/kuli-entity';

export function isProductNotAvailable(status: PRODUCT_STATUS) {
  return [
    PRODUCT_STATUS.CLOSED,
    PRODUCT_STATUS.MERGED,
    PRODUCT_STATUS.RETOUR,
    PRODUCT_STATUS.SOLD,
  ].includes(status);
}
