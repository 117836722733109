import type { UseMutationOptions, UseMutationReturnType, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { Ref } from 'vue';

import { useMutation, useQuery } from '@tanstack/vue-query';
import { type HttpsCallableOptions, httpsCallable } from 'firebase/functions';

import { firebaseFunctions } from '~~/api/api.firebase';

interface FirebaseFunctionsParams<RequestData = any> {
  data?: RequestData | null;
  fnName: string;
  fnOptions?: HttpsCallableOptions;
}

export async function doHttpsCall<
  RequestData = any,
  ResponseData = unknown,
>({
  data,
  fnName,
  fnOptions,
}: FirebaseFunctionsParams<RequestData>) {
  const response = await httpsCallable<RequestData, ResponseData>(
    firebaseFunctions,
    fnName,
    fnOptions,
  )(data);

  return response.data;
}

export function useFunctionsQuery<
  RequestData = any,
  ResponseData = unknown,
  ModifiedData = ResponseData,
>({
  data,
  fnName,
  fnOptions,
  queryOptions,
}: {
  queryOptions?: Omit<
    Exclude<UseQueryOptions<ResponseData, Error, ModifiedData>, Ref>,
    'queryFn'
  >;
} & FirebaseFunctionsParams<RequestData>): UseQueryReturnType<ModifiedData, Error> {
  return useQuery<ResponseData, Error, ModifiedData>({
    ...queryOptions,
    queryKey: queryOptions?.queryKey ?? [fnName],
    async queryFn() {
      return doHttpsCall<RequestData, ResponseData>({
        data,
        fnName,
        fnOptions,
      });
    },
  });
}

export function useFunctionsCall<
  RequestData = any,
  ResponseData = unknown,
>({
  fnName,
  fnOptions,
  mutationOptions,
}: {
  fnName: string;
  fnOptions?: HttpsCallableOptions;
  mutationOptions?: UseMutationOptions<ResponseData, Error, RequestData, unknown>;
}): UseMutationReturnType<ResponseData, Error, RequestData, unknown> {
  return useMutation<ResponseData, Error, RequestData, unknown>({
    ...mutationOptions,
    mutationFn: async (data) => {
      const response = await httpsCallable<RequestData, ResponseData>(
        firebaseFunctions,
        fnName,
        fnOptions,
      )(data);

      return response.data;
    },
  });
}
