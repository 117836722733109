import { darkTheme } from 'naive-ui';
import { computed } from 'vue';

import { useThemeStore } from '~~/designs/theme/theme.store';
import { naiveLocales, useLocale } from '~~/locales';

export function useNaiveProvider() {
  const { getLocale } = useLocale();
  const themeStore = useThemeStore();

  const naiveDarkTheme = computed(() => (themeStore.isDarkMode ? darkTheme : undefined));

  const naiveLocale = computed(() => naiveLocales[getLocale.value]);

  return {
    naiveDarkTheme,
    naiveLocale,
  };
}
