<script lang="ts" setup>
import { dateNlNL } from 'naive-ui';

import { useNaiveProvider } from './core/composables/use-naive-provider';
import { useThemeStore } from './designs/theme/theme.store';

const themeStore = useThemeStore();

const { naiveDarkTheme, naiveLocale } = useNaiveProvider();
</script>

<template>
  <NConfigProvider
    :theme="naiveDarkTheme"
    :theme-overrides="themeStore.naiveTheme"
    :locale="naiveLocale"
    :date-locale="dateNlNL"
    class="h-full"
  >
    <CoreProvider>
      <RouterView class="bg-layout" />
    </CoreProvider>
  </NConfigProvider>
</template>
