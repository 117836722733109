import { useLocalStorage } from '@vueuse/core';
import { computed } from 'vue';

import type { KuliI18n } from './locale.types';

import { LOCALE, LOCALE_KEY } from './locale.entity';

const store = useLocalStorage<KuliI18n['LocaleType']>(LOCALE_KEY, LOCALE.en);

export function setLocale(locale: KuliI18n['LocaleType']) {
  store.value = locale;
}

export const getLocale = computed(() => store.value);
