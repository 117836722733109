import type { RouteRecordRaw } from 'vue-router';

import { EXCEPTION_COMPONENT, LAYOUT_DEFAULT, NOT_FOUND_ROUTE_NAME, ROOT_ROUTE_NAME } from '~~/router/router.entity';

export const ROOT_ROUTE: RouteRecordRaw = {
  name: ROOT_ROUTE_NAME,
  path: '/',
  redirect: import.meta.env.KULI_ROOT_PATH,
  meta: {
    title: 'Root',
    ignoreAuth: true,
  },
};

const NOT_FOUND_ROUTE: RouteRecordRaw = {
  name: NOT_FOUND_ROUTE_NAME,
  path: '/:path(.*)*',
  component: LAYOUT_DEFAULT,
  meta: {
    title: 'ErrorPagex',
    i18nKey: 'routes.error',
  },
  children: [
    {
      component: EXCEPTION_COMPONENT,
      name: NOT_FOUND_ROUTE_NAME,
      path: '/:path(.*)*',
      meta: {
        title: 'Not found',
        i18nKey: 'routes.error',
      },
    },
  ],
};

export const BUILTIN_ROUTES: Array<RouteRecordRaw> = [
  ROOT_ROUTE,
  NOT_FOUND_ROUTE,
];
