import type { SkyProductSize } from '@vinicunca/kuli-entity';

import { FIRESTORE_COLLECTION } from '@vinicunca/kuli-entity';

import { BaseRepository } from '~~/api/repositories/repository-factory';

export class SizeRepository extends BaseRepository<SkyProductSize> {
  constructor() {
    super(FIRESTORE_COLLECTION.SIZES);
  }

  async getAll() {
    return this.executeQuery(this.collectionRef);
  }
}
