import { unref } from 'vue';

import type { KuliI18n } from './locale.types';

import { loadLocalePool, setHtmlPageLang } from './locale.helper';
import { i18n } from './locale.setup';
import { getLocale, setLocale } from './locale.store';

export function useLocale() {
  async function changeLocale(locale: KuliI18n['LocaleType']) {
    const globalI18n = i18n.global;
    const currentLocale = unref(globalI18n.locale);

    if (currentLocale === locale) {
      return locale;
    }

    if (loadLocalePool.includes(locale)) {
      setI18nLanguage(locale);

      return;
    }

    const langModule = ((await import(`./langs/${locale}.ts`)) as any).default;

    if (!langModule) {
      return;
    }

    const { message } = langModule;

    globalI18n.setLocaleMessage(locale, message);

    loadLocalePool.push(locale);

    setI18nLanguage(locale);
  }

  return {
    changeLocale,
    getLocale,
  };
}

function setI18nLanguage(locale: KuliI18n['LocaleType']) {
  (i18n.global.locale as any).value = locale;
  setLocale(locale);
  setHtmlPageLang(locale);
}
