import type { RouteRecordRaw } from 'vue-router';

import type { KuliBreadcrumb, KuliMenu } from '~~/router/menu/menu.types';

import { getLayoutMenuByBaseRoute } from './menu.utils';

/**
 * Get breadcrumbs by route
 */
export function getBreadcrumbsByRoute(
  { menus, route }:
  { menus: Array<KuliMenu>; route: RouteRecordRaw },
): Array<KuliBreadcrumb> {
  const key = route.name as string;
  const activeKey = route.meta?.activeMenu;

  const menuKey = activeKey || key;

  for (const menu of menus) {
    if (menu.key === menuKey) {
      const breadcrumbMenu = menuKey !== activeKey
        ? menu
        : getLayoutMenuByBaseRoute(route);

      return [transformMenuToBreadcrumb(breadcrumbMenu)];
    }

    if (menu.children?.length) {
      const result = getBreadcrumbsByRoute({ route, menus: menu.children });
      if (result.length > 0) {
        return [transformMenuToBreadcrumb(menu), ...result];
      }
    }
  }

  return [];
}

/**
 * Transform menu to breadcrumb
 */
function transformMenuToBreadcrumb(menu: KuliMenu) {
  const { children, ...rest } = menu;

  const breadcrumb: KuliBreadcrumb = {
    ...rest,
  };

  if (children?.length) {
    breadcrumb.options = children.map(transformMenuToBreadcrumb);
  }

  return breadcrumb;
}
