import { getColorPalette } from '@vinicunca/color-palette';
import { useEventListener, useLocalStorage, usePreferredColorScheme } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, effectScope, onScopeDispose, ref, toRefs, watch } from 'vue';

import { SETUP_STORE_ID } from '~~/store/store.entity';

import type { KuliTheme } from './theme.types';

import { BACKUP_BEFORE_MOBILE_KEY, DEFAULT_THEME, MIXED_SIDEBAR_FIXED_KEY, THEME_KEY, THEME_OVERRIDE_KEY } from './theme.entity';
import { addThemeVarsToHtml, createThemeToken, getNaiveTheme, initThemeSettings, toggleCssDarkMode } from './theme.utils';

export const themeLocalStorage = useLocalStorage<KuliTheme['ThemeSetting']>(
  THEME_KEY,
  DEFAULT_THEME,
);

export const themeOverrideLocalStorage = useLocalStorage(THEME_OVERRIDE_KEY, __BUILD_TIME__);

export const backupThemeBeforeMobileLocalStorage = useLocalStorage<{
  layout: KuliTheme['ThemeLayoutMode'];
  sidebarCollapse: boolean;
}>(BACKUP_BEFORE_MOBILE_KEY, null);

export const isMixSidebarFixedLocalStorage = useLocalStorage<boolean>(MIXED_SIDEBAR_FIXED_KEY, false);

export const useThemeStore = defineStore(SETUP_STORE_ID.THEME, () => {
  const scope = effectScope();
  const osTheme = usePreferredColorScheme();

  /** Theme settings */
  const settings = ref<KuliTheme['ThemeSetting']>(initThemeSettings());

  /** Dark mode */
  const isDarkMode = computed(() => {
    if (settings.value.themeScheme === 'auto') {
      return osTheme.value === 'dark';
    }

    return settings.value.themeScheme === 'dark';
  });

  /** Theme colors */
  const themeColors = computed(() => {
    const { isInfoFollowPrimary, otherColor, themeColor } = settings.value;
    const colors: KuliTheme['ThemeColor'] = {
      primary: themeColor,
      ...otherColor,
      info: isInfoFollowPrimary ? themeColor : otherColor.info,
    };

    return colors;
  });

  /** Naive theme */
  const naiveTheme = computed(() => getNaiveTheme(themeColors.value));

  /**
   * Settings json
   * @description Used for copy settings into json
   */
  const settingsJson = computed(() => JSON.stringify(settings.value));

  /** Reset store */
  function resetStore() {
    const themeStore = useThemeStore();

    themeStore.$reset();
  }

  function setThemeScheme(themeScheme: KuliTheme['ThemeScheme']) {
    settings.value.themeScheme = themeScheme;
  }

  function toggleThemeScheme() {
    const themeSchemes: Array<KuliTheme['ThemeScheme']> = ['light', 'dark', 'auto'];

    const index = themeSchemes.findIndex((item) => item === settings.value.themeScheme);

    const nextIndex = index === themeSchemes.length - 1 ? 0 : index + 1;

    const nextThemeScheme = themeSchemes[nextIndex];

    setThemeScheme(nextThemeScheme);
  }

  function updateThemeColors(key: KuliTheme['ThemeColorKey'], color: string) {
    // get a color palette by provided color and color name, and use the suitable color
    const { main } = getColorPalette(color);

    if (key === 'primary') {
      settings.value.themeColor = main.hex;
    } else {
      settings.value.otherColor[key] = main.hex;
    }
  }

  function setThemeLayout(mode: KuliTheme['ThemeLayoutMode']) {
    settings.value.layout.mode = mode;
  }

  /** Setup theme vars to html */
  function setupThemeVarsToHtml() {
    const { darkThemeTokens, themeTokens } = createThemeToken(themeColors.value);
    addThemeVarsToHtml(themeTokens, darkThemeTokens);
  }

  function cacheThemeSettings() {
    const isProd = import.meta.env.PROD;

    if (!isProd) {
      return;
    }

    themeLocalStorage.value = settings.value;
  }

  // cache theme settings when page is closed or refreshed
  useEventListener(window, 'beforeunload', () => {
    cacheThemeSettings();
  });

  // watch store
  scope.run(() => {
    // watch dark mode
    watch(
      isDarkMode,
      (val) => {
        toggleCssDarkMode(val);
      },
      { immediate: true },
    );

    // themeColors change, update css vars
    watch(
      themeColors,
      () => {
        setupThemeVarsToHtml();
      },
      { immediate: true },
    );
  });

  /** On scope dispose */
  onScopeDispose(() => {
    scope.stop();
  });

  return {
    ...toRefs(settings.value),

    isDarkMode,
    naiveTheme,
    resetStore,
    setThemeLayout,
    setThemeScheme,
    settingsJson,
    themeColors,
    toggleThemeScheme,
    updateThemeColors,
  };
});
