import { breakpointsTailwind, useBreakpoints, useEventListener } from '@vueuse/core';
import { defineStore } from 'pinia';
import { effectScope, nextTick, onScopeDispose, ref, watch } from 'vue';

import { backupThemeBeforeMobileLocalStorage, isMixSidebarFixedLocalStorage, useThemeStore } from '~~/designs/theme/theme.store';
import { getLocale } from '~~/locales/locale.store';
import { useRouterStore } from '~~/router/router.store';
import { useTabStore } from '~~/router/tab/tab.store';
import { SETUP_STORE_ID } from '~~/store/store.entity';

export const useCoreStore = defineStore(SETUP_STORE_ID.CORE, () => {
  const tabStore = useTabStore();
  const themeStore = useThemeStore();
  const routerStore = useRouterStore();

  const breakpoints = useBreakpoints(breakpointsTailwind);

  const isSidebarCollapse = ref(false);
  const isContentXScrollable = ref(false);
  const isFullContent = ref(false);
  const isMixSidebarFixed = ref(isMixSidebarFixedLocalStorage.value);
  const isThemeDrawerVisible = ref(false);

  const reloadFlag = ref(true);

  /**
   * Reload page
   */
  async function reloadPage(duration = 300) {
    reloadFlag.value = false;

    const duration_ = themeStore.page.animate ? duration : 40;

    await new Promise((resolve) => {
      setTimeout(resolve, duration_);
    });

    reloadFlag.value = true;
  }

  /** Is mobile layout */
  const isMobile = breakpoints.smaller('sm');

  const scope = effectScope();

  scope.run(() => {
    // watch isMobile, if is mobile, collapse sidebar
    watch(
      isMobile,
      (newValue) => {
        if (newValue) {
          backupThemeBeforeMobileLocalStorage.value = {
            layout: themeStore.layout.mode,
            sidebarCollapse: isSidebarCollapse.value,
          };

          themeStore.setThemeLayout('vertical');
          isSidebarCollapse.value = true;
        } else {
          if (backupThemeBeforeMobileLocalStorage.value) {
            nextTick(() => {
              themeStore.setThemeLayout(backupThemeBeforeMobileLocalStorage.value.layout);
              isSidebarCollapse.value = backupThemeBeforeMobileLocalStorage.value.sidebarCollapse;
            });

            backupThemeBeforeMobileLocalStorage.value = null;
          }
        }
      },
      { immediate: true },
    );

    // watch locale
    watch(getLocale, () => {
      // update global menus by locale
      routerStore.updateLayoutMenusByLocale();

      // update tabs by locale
      tabStore.updateTabsByLocale();
    });
  });

  // cache mixSiderFixed
  useEventListener(window, 'beforeunload', () => {
    isMixSidebarFixedLocalStorage.value = isMixSidebarFixed.value;
  });

  /** On scope dispose */
  onScopeDispose(() => {
    scope.stop();
  });

  return {
    isContentXScrollable,
    isFullContent,
    isMobile,
    isSidebarCollapse,
    isThemeDrawerVisible,
    isMixSidebarFixed,
    reloadFlag,
    reloadPage,
  };
});
