/* eslint-disable perfectionist/sort-imports */
import './plugins/assets';

import { createApp } from 'vue';

import App from './app.vue';
import { setupI18n } from './locales';
import {
  initAppState,
  renderLoader,
  setupDayjs,
  setupFirebase,
  setupNProgress,
  setupVueQuery,
} from './plugins';
import { setupRouter } from './router';
import { setupStore } from './store';

(async () => {
  // app loading
  renderLoader();

  // progress bar
  setupNProgress();

  // dayjs
  setupDayjs();

  const app = createApp(App);

  // Store
  setupStore(app);

  // Vue Query
  setupVueQuery(app);

  /**
   * Multilingual configuration.
   * Asynchronous case: language files may be obtained from the server side.
   *
   * Make sure this runs before the router since the route depends on the the locales.
   */
  await setupI18n(app);

  // setup firebase
  await setupFirebase(app);

  // Seed the initial app state
  await initAppState();

  // Init Router
  await setupRouter(app);

  // mount app
  app.mount('#app');
})();
