import type { SkyActivityLog } from '@vinicunca/kuli-entity';

import { FIRESTORE_COLLECTION } from '@vinicunca/kuli-entity';
import { orderBy, query } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { flavourRepo, parentProductRepo, userRepo } from '~~/api/repositories';
import { BaseRepository } from '~~/api/repositories/repository-factory';
import { useAuthStore } from '~~/auth/auth.store';

export class MasterActivityRepository extends BaseRepository<SkyActivityLog> {
  constructor() {
    super(FIRESTORE_COLLECTION.MASTER_ACTIVITY_LOG);
  }

  async getActivityLogsWithDetail() {
    const querySnapshot = query(this.collectionRef, orderBy('createdAt', 'desc'));
    const activities = await this.executeQuery(querySnapshot);

    const employeesSet = new Set(activities.map((item) => item.employeeRef!.id));
    const parentProductsSet = new Set(activities.map((item) => item.parentProductRef!.id));
    const flavoursSet = new Set(activities.map((item) => item.flavourRef!.id));

    const [employeeDetails, parentProductDetails, flavourDetails] = await Promise.all([
      Promise.all(Array.from(employeesSet).map((ref) => userRepo.getDocument(ref))),
      Promise.all(Array.from(parentProductsSet).filter(Boolean).map((ref) => parentProductRepo.getDocument(ref))),
      Promise.all(Array.from(flavoursSet).filter(Boolean).map((ref) => flavourRepo.getDocument(ref))),
    ]);

    return activities.map((item) => {
      const { employeeRef, parentProductRef, flavourRef, productRef, ...resData } = item;

      return {
        ...resData,
        employeeDetail: employeeDetails.find((detail) => detail?.id === employeeRef?.id),
        parentProductDetail: parentProductDetails.find((detail) => detail?.id === parentProductRef?.id),
        flavourDetail: flavourDetails.find((detail) => detail?.id === flavourRef?.id),
      };
    });
  }

  async addActivity(details: Omit<SkyActivityLog, 'id'>) {
    const { currentUser } = useAuthStore();
    const currentUserRef = userRepo.getDocRef(currentUser!.id);

    const id = uuidv4();
    await this.addDocument({ data: { ...details, employeeRef: currentUserRef }, id });
  }
}
