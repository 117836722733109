import type { App } from 'vue';
import type { I18nOptions } from 'vue-i18n';

import { createI18n } from 'vue-i18n';

import { localeSetting } from './locale.entity';
import { setLoadLocalePool } from './locale.helper';
import { getLocale } from './locale.store';

// eslint-disable-next-line import/no-mutable-exports
export let i18n: ReturnType<typeof createI18n>;

async function createI18nOptions(): Promise<I18nOptions> {
  const currentLocale = getLocale.value;
  const localeModule = await import(`./langs/${currentLocale}.ts`);
  const message = localeModule.default?.message ?? {};

  setLoadLocalePool((loadLocalePool) => loadLocalePool.push(currentLocale));

  return {
    availableLocales: localeSetting.availableLocales,
    fallbackLocale: localeSetting.fallback,
    legacy: false,
    locale: currentLocale,
    messages: {
      [currentLocale]: message,
    },
  };
}

export async function setupI18n(app: App) {
  const options = await createI18nOptions();
  i18n = createI18n(options);
  app.use(i18n);
}
