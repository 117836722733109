import type { FIRESTORE_COLLECTION } from '@vinicunca/kuli-entity';
import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Query,
} from 'firebase/firestore';

import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc as docRef,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import type { AddDocumentOptions, FirestoreData, UpdateDocumentOptions } from '~~/api/repositories/repository.types';

import { firestoreInstance } from '~~/api/api.firebase';

export class BaseRepository<T, D extends DocumentData = DocumentData> {
  collectionRef: CollectionReference<T, D>;

  constructor(collectionName: FIRESTORE_COLLECTION) {
    this.collectionRef = collection(firestoreInstance, collectionName) as CollectionReference<T, D>;
  }

  async fieldName<T>(name: keyof T) {
    return name;
  }

  getDocRef(id: string): DocumentReference<T, D> {
    return docRef<T, D>(this.collectionRef, id);
  }

  async executeQuery(query: Query<T, D>) {
    const querySnapshot = await getDocs<T, D>(query)
      .catch((error) => {
        this.displayError(error);
        return null;
      });

    if (querySnapshot === null) {
      return [];
    }

    return querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ref: doc.ref,
        ...doc.data(),
      };
    });
  }

  async getDocument(id: string): Promise<T | null> {
    const document_ = await getDoc<T, D>(this.getDocRef(id))
      .catch((error) => {
        this.displayError(error);
        return null;
      });

    if (document_?.exists()) {
      return {
        id,
        ...document_.data(),
      };
    } else {
      return null;
    }
  }

  protected async addDocument({ id, data, options = {} }: AddDocumentOptions<FirestoreData<T>>): Promise<void> {
    const docRef = this.getDocRef(id);

    return setDoc<FirestoreData<T>, D>(docRef, data, options)
      .catch((error) => {
        this.displayError(error);
      });
  }

  protected async updateDocument({ id, data }: UpdateDocumentOptions<D>): Promise<void> {
    const docRef = this.getDocRef(id);

    return updateDoc<T, D>(docRef, data)
      .catch((error) => {
        this.displayError(error);
      });
  }

  private displayError(error: unknown) {
    if (error instanceof FirebaseError) {
      window.$message?.error(`${error.code}: ${error.message}`);
    }
  }
}
