import type { ACTIVITY_TYPES, FirestoreStockReport, ProductStockReportFilterInput, SkyParentProduct, SkyProduct } from '@vinicunca/kuli-entity';
import type { UpdateData } from 'firebase/firestore';

import { useQuery } from '@tanstack/vue-query';
import { ACTIVE_STATUSES, FIRESTORE_COLLECTION, PRODUCT_STATUS } from '@vinicunca/kuli-entity';
import { Timestamp, getCountFromServer, getDocs, limit, orderBy, query, startAt, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import type { FirestoreData } from '~~/api/repositories/repository.types';
import type { FormFieldsParentProduct } from '~~/modules/parent-product/parent-product.entity';
import type { SkyStockFilter } from '~~/modules/stock/stock.entity';

import { QUERY_KEY } from '~~/api/api.query';
import { flavourRepo, masterActivityRepo, parentProductRepo, productRepo } from '~~/api/repositories';
import { BaseRepository } from '~~/api/repositories/repository-factory';

interface ReportRow extends SkyParentProduct {
  childItems: Array<SkyProduct>;
}

export class ParentProductRepository extends BaseRepository<SkyParentProduct> {
  constructor() {
    super(FIRESTORE_COLLECTION.PARENT_PRODUCTS);
  }

  async addParentProduct(formFields: FormFieldsParentProduct, activityType: ACTIVITY_TYPES) {
    const flavourDocRef = flavourRepo.getDocRef(formFields.flavour!);
    const data: FirestoreData<SkyParentProduct> = {
      ...formFields,
      restGrams: formFields.initialGrams,
      createdAt: Timestamp.fromMillis(formFields.createdAt),
      processedAt: Timestamp.fromMillis(formFields.processedAt),
      flavourRef: flavourDocRef,
      handledBy: null,
    };

    const id = uuidv4();

    await this.addDocument({
      data,
      id,
    }).then(() => {
      flavourRepo.increaseParentStockByOne(flavourDocRef.id);
      const parentProductDocRef = parentProductRepo.getDocRef(id);
      masterActivityRepo.addActivity({
        flavourRef: flavourDocRef,
        parentProductRef: parentProductDocRef,
        activityType,
        createdAt: Timestamp.now(),
      });
    });

    return id;
  }

  editParentProduct(input: UpdateData<SkyParentProduct>) {
    const { id, ...data } = input;
    return this.updateDocument({
      id: id as string,
      data,
    });
  }

  returnItem(input: SkyParentProduct) {
    this.editParentProduct({
      id: input.id,
      status: PRODUCT_STATUS.RETOUR,
    });

    flavourRepo.decreaseParentStockByOne(input.flavourRef.id);
  }

  async getTotalProducts(flavourId: string) {
    const flavourRef = flavourRepo.getDocRef(flavourId);

    const query_ = query(
      this.collectionRef,
      where('flavourRef', '==', flavourRef),
      where('status', 'in', ACTIVE_STATUSES),
    );

    const snapshotCount = await getCountFromServer(query_);

    return snapshotCount.data().count;
  }

  queryByFlavour(flavourId: string) {
    return useQuery({
      queryKey: [QUERY_KEY.PARENT_BY_FLAVOUR, {
        id: flavourId,
      }],
      queryFn: () => {
        const flavourRef = flavourRepo.getDocRef(flavourId);

        const query_ = query(
          this.collectionRef,
          where('flavourRef', '==', flavourRef),
        );

        return this.executeQuery(query_);
      },
      initialData: [],
    });
  }

  async getProcessedParentInPeriod(filterInput: number) {
    const previousYear = filterInput - 1;

    // Create an array to store counts for each month
    const monthCounts = Array(12).fill(0);

    const q = query(
      this.collectionRef,
      where('createdAt', '>=', Timestamp.fromDate(new Date(previousYear, 0, 1))), // Start of previous year
      where('createdAt', '<', Timestamp.fromDate(new Date(filterInput, 0, 1))), // Start of current year
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const createdAt = doc.data().createdAt.toDate();
      const month = createdAt.getMonth();
      monthCounts[month]++;
    });
    return monthCounts.map((count) => ({
      count,
    }));
  }

  async getStockReport(filters: { page: number; search: SkyStockFilter }) {
    const pageSize = 30;
    const startIndex = (filters.page - 1) * pageSize;

    let countQuery = query(this.collectionRef);
    let snapshotQuery = query(
      this.collectionRef,
      orderBy('createdAt', 'desc'),
      limit(pageSize),
    );

    if (filters.page > 1) {
      const startSnapshot = await getDocs(
        query(
          this.collectionRef,
          orderBy('createdAt', 'desc'),
          limit(startIndex),
        ),
      );
      const lastVisible = startSnapshot.docs[startSnapshot.docs.length - 1];
      snapshotQuery = query(
        snapshotQuery,
        startAt(lastVisible),
      );
    }

    if (filters.search.flavour.length) {
      const flavourDocRefs = filters.search.flavour.map(
        (item) => flavourRepo.getDocRef(item),
      );
      snapshotQuery = query(snapshotQuery, where('flavourRef', 'in', flavourDocRefs));
      countQuery = query(countQuery, where('flavourRef', 'in', flavourDocRefs));
    }

    if (!filters.search.allTimeChecked) {
      const dateStart = Timestamp.fromMillis(filters.search.dateRange[0]);
      const dateEnd = Timestamp.fromMillis(filters.search.dateRange[1]);
      snapshotQuery = query(
        snapshotQuery,
        where('createdAt', '>=', dateStart),
        where('createdAt', '<=', dateEnd),
      );
      countQuery = query(
        countQuery,
        where('createdAt', '>=', dateStart),
        where('createdAt', '<=', dateEnd),
      );
    }

    if (filters.search.batchStatus.length) {
      snapshotQuery = query(snapshotQuery, where('status', 'in', filters.search.batchStatus));
      countQuery = query(countQuery, where('status', 'in', filters.search.batchStatus));
    }

    const parentItems = await this.executeQuery(snapshotQuery);
    const parentRefs = parentItems.map((item) => item.ref);

    let childProductsQuery = query(
      productRepo.collectionRef,
      where('parentRef', 'in', parentRefs),
    );

    if (filters.search.childStatus.length) {
      childProductsQuery = query(
        childProductsQuery,
        where('status', 'in', filters.search.childStatus),
      );
    }

    if (filters.search.size.length) {
      childProductsQuery = query(
        childProductsQuery,
        where('size.id', '==', filters.search.size[0]),
      );
    }

    const childItems = await productRepo.executeQuery(childProductsQuery);

    const rows: Array<ReportRow> = [];

    parentItems.forEach((parentItem) => {
      const children = childItems.filter((childItem) => {
        if (childItem.parentRef.id !== parentItem.id) {
          return false;
        }

        let matchesSize = true;
        let matchesStatus = true;

        if (filters.search.size.length) {
          matchesSize = filters.search.size.includes(childItem.size.id);
        }

        if (filters.search.childStatus.length) {
          matchesStatus = filters.search.childStatus.includes(childItem.status);
        }

        return matchesSize && matchesStatus;
      });

      if (children.length) {
        rows.push({ ...parentItem, childItems: children });
      } else if (!filters.search.size.length && !filters.search.childStatus.length) {
        const allChildren = childItems.filter((childItem) => childItem.parentRef.id === parentItem.id);
        rows.push({ ...parentItem, childItems: allChildren });
      }
    });

    const countSnapshot = await getCountFromServer(countQuery);
    const totalCount = countSnapshot.data().count;

    return { rows, totalCount };
  }

  async getDataForStockReport(queryInput: ProductStockReportFilterInput): Promise<{ parentItems: Array<FirestoreStockReport> }> {
    const { allTimeDateChecked, flavours, batchStatus, childStatus, dateRange, size } = queryInput;
    let snapshotQuery = query(this.collectionRef);
    let childProductSnapshotQuery = query(productRepo.collectionRef);

    // DATE FILTER
    if (!allTimeDateChecked) {
      const dateStart = Timestamp.fromMillis(dateRange[0]);
      const dateEnd = Timestamp.fromMillis(dateRange[1]);
      snapshotQuery = query(snapshotQuery, where('createdAt', '>=', dateStart), where('createdAt', '<=', dateEnd));
    }
    // FLAVOURS FILTER
    if (flavours.length > 0) {
      const flavourDocRefConstraint = flavours.map((item) => flavourRepo.getDocRef(item));
      snapshotQuery = query(snapshotQuery, where('flavourRef', 'in', flavourDocRefConstraint));
    }
    // PARENT_STATUS FILTER
    if (batchStatus.length > 0) {
      snapshotQuery = query(snapshotQuery, where('status', 'in', batchStatus));
    }
    // CHILD_STATUS FILTER
    if (childStatus.length > 0) {
      childProductSnapshotQuery = query(childProductSnapshotQuery, where('status', 'in', childStatus));
    }

    if (size.length > 0) {
      childProductSnapshotQuery = query(childProductSnapshotQuery, where('size.kassaDesc', 'in', size));
    }
    const parentItems: Array<FirestoreStockReport> = await this.executeQuery(snapshotQuery);

    const fetchedParentRefs = parentItems.map((item) => parentProductRepo.getDocRef(item.id));

    const MAX_BATCH_SIZE = 40;
    const childItemsPromises = []; // Array to store promises for fetching child items
    for (let i = 0; i < fetchedParentRefs.length; i += MAX_BATCH_SIZE) {
      const batch = fetchedParentRefs.slice(i, i + MAX_BATCH_SIZE);
      const batchQuery = query(childProductSnapshotQuery, where('parentRef', 'in', batch));
      const promise = productRepo.executeQuery(batchQuery);
      childItemsPromises.push(promise);
    }

    // Wait for all child item promises to resolve
    const childItemsArrays = await Promise.all(childItemsPromises);

    // Merge child items arrays into a single array
    const childItems = childItemsArrays.flat();

    // Fetch flavour labels for parent items
    const flavourPromises = parentItems.map((parentItem) => flavourRepo.getDocument(parentItem.flavourRef.id));
    const flavourLabels = await Promise.all(flavourPromises);

    // Assign child items and flavourLabel to their respective parent items
    parentItems.forEach((parentItem: any, index) => {
      const parentId = parentItem.id;
      parentItem.childItems = childItems.filter((childItem) => childItem.parentRef.id === parentId);
      parentItem.flavourLabel = flavourLabels[index]!.typeLabel;
    });

    return { parentItems };
  }
}
