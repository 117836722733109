import type { KuliTheme } from './theme.types';

export const THEME_KEY = 'THEME__';
export const THEME_OVERRIDE_KEY = 'THEME_OVERRIDE__';
export const BACKUP_BEFORE_MOBILE_KEY = 'BACKUP_BEFORE_MOBILE__';
export const MIXED_SIDEBAR_FIXED_KEY = 'MIXED_SIDEBAR_FIXED__';

/** Default theme settings */
export const DEFAULT_THEME: KuliTheme['ThemeSetting'] = {
  fixedHeaderAndTab: true,
  footer: {
    fixed: false,
    height: 48,
    right: true,
    visible: false,
  },
  header: {
    breadcrumb: {
      showIcon: true,
      visible: true,
    },
    height: 100,
  },
  isInfoFollowPrimary: true,
  layout: {
    mode: 'horizontal',
    scrollMode: 'content',
  },
  otherColor: {
    error: '#f5222d',
    info: '#2080f0',
    success: '#52c41a',
    warning: '#faad14',
  },
  page: {
    animate: true,
    animateMode: 'fade-slide',
  },
  sidebar: {
    collapsedWidth: 64,
    inverted: false,
    mixChildMenuWidth: 200,
    mixCollapsedWidth: 64,
    mixWidth: 90,
    width: 220,
  },
  tab: {
    cache: true,
    height: 44,
    mode: 'chrome',
    visible: true,
  },
  themeColor: '#2A8FE8',
  themeScheme: 'light',
};

/**
 * Override theme settings
 *
 * If publish new version, use `overrideThemeSettings` to override certain theme settings
 */
export const overrideThemeSettings: Partial<KuliTheme['ThemeSetting']> = {};
