import { getCurrentUser } from 'vuefire';

import { useAuthStore } from '~~/auth/auth.store';
import { useProductStore } from '~~/modules/product/product.store';

// @unocss-include
export function renderLoader() {
  const loadingHtml = `
  <div class="fixed-lt wh-full flex-center flex-col bg-[linear-gradient(131deg,#fe6492,#ffd34b)]">
    <img
      src="${import.meta.env.KULI_SETUP_LOADING_IMG}"
      alt="Logo"
    >

    <i class="icon-svg-spinners:tadpole my-36px h-56px w-56px color-#2d4a61">
    </i>

    <h2 class="text-28px color-#2d4a61 font-500">
      ${import.meta.env.KULI_APP_TITLE}
    </h2>
  </div>
  `;
  const app = document.getElementById('app');

  if (app) {
    app.innerHTML = loadingHtml;
  }
}

export async function initAppState() {
  const authStore = useAuthStore();
  const productStore = useProductStore();

  /**
   * This line is important since we want the firebase auth to finish checking if
   * there are any current user that has been logged in.
   */
  await getCurrentUser();

  /**
   * Unfortunately we have to store the user in the firebase's auth and
   * also in the firestore. Therefore we need to populate the current user.
   */
  await authStore.populateCurrentUser();

  /**
   * We need to populate the sizes because it's being used across the app.
   */
  await productStore.initSizes();

  /**
   * To avoid showing two loading spinner we fetch all users first.
   */
  if (!authStore.currentUser) {
    await authStore.fetchAllUsers();
  }
}
