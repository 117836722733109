import { useEventListener, useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { KuliTab, KuliTabRoute } from '~~/router/tab';

import { useThemeStore } from '~~/designs/theme/theme.store';
import { router } from '~~/router';
import { NOT_FOUND_ROUTE_NAME } from '~~/router/router.entity';
import { useRouterStore } from '~~/router/router.store';
import { SETUP_STORE_ID } from '~~/store/store.entity';

import { filterTabsByAllRoutes, filterTabsById, filterTabsByIds, findTabByRouteName, getAllTabs, getDefaultHomeTab, getFixedTabIds, getTabByRoute, isTabInTabs, updateTabByI18nKey, updateTabsByI18nKey } from './tab.utils';

export const tabLocalStorage = useLocalStorage<Array<KuliTab>>('TABS__', []);

export const useTabStore = defineStore(SETUP_STORE_ID.TAB, () => {
  const themeStore = useThemeStore();
  const routerStore = useRouterStore();

  /** Tabs */
  const tabs = ref<Array<KuliTab>>([]);

  /** Get active tab */
  const homeTab = ref<KuliTab>();

  /** Init home tab */
  function initHomeTab() {
    homeTab.value = getDefaultHomeTab(routerStore.routeObject);
  }

  /** Get all tabs */
  const allTabs = computed(() => getAllTabs({
    homeTab: homeTab.value,
    tabs: tabs.value,
  }));

  /** Active tab id */
  const activeTabId = ref<string>('');

  /**
   * Set active tab id
   */
  function setActiveTabId(id: string) {
    activeTabId.value = id;
  }

  /**
   * Init tab store
   */
  function initTabStore(currentRoute: KuliTabRoute) {
    if (themeStore.tab.cache && tabLocalStorage.value.length > 0) {
      const filteredTabs = filterTabsByAllRoutes({
        router,
        tabs: tabLocalStorage.value,
      });

      tabs.value = updateTabsByI18nKey(filteredTabs);
    }

    addTab(currentRoute);
  }

  /**
   * Add tab
   *
   * @param route Tab route
   * @param active Whether to activate the added tab
   */
  function addTab(route: KuliTabRoute, active = true) {
    const tab = getTabByRoute(route);

    const isHomeTab = tab.id === homeTab.value?.id;

    if (
      !isHomeTab
      && !isTabInTabs({ tabId: tab.id, tabs: tabs.value })
      && route.name !== NOT_FOUND_ROUTE_NAME
    ) {
      tabs.value.push(tab);
    }

    if (active) {
      setActiveTabId(tab.id);
    }
  }

  /**
   * Remove tab
   */
  async function removeTab(tabId: string) {
    const isRemoveActiveTab = activeTabId.value === tabId;
    const updatedTabs = filterTabsById({ tabId, tabs: tabs.value });

    function update() {
      tabs.value = updatedTabs;
    }

    if (!isRemoveActiveTab) {
      update();
      return;
    }

    const activeTab = updatedTabs.at(-1) || homeTab.value;

    if (activeTab) {
      await switchRouteByTab(activeTab);
      update();
    }
  }

  /** remove active tab */
  async function removeActiveTab() {
    await removeTab(activeTabId.value);
  }

  /**
   * remove tab by route name
   */
  async function removeTabByRouteName(routeName: string) {
    const tab = findTabByRouteName({ name: routeName, tabs: tabs.value });
    if (!tab) {
      return;
    }

    await removeTab(tab.id);
  }

  /**
   * Clear tabs
   */
  async function clearTabs(excludes: Array<string> = []) {
    const remainTabIds = [...getFixedTabIds(tabs.value), ...excludes];
    const removedTabsIds = tabs.value.map((tab) => tab.id).filter((id) => !remainTabIds.includes(id));

    const isRemoveActiveTab = removedTabsIds.includes(activeTabId.value);
    const updatedTabs = filterTabsByIds({ tabIds: removedTabsIds, tabs: tabs.value });

    function update() {
      tabs.value = updatedTabs;
    }

    if (!isRemoveActiveTab) {
      update();
      return;
    }

    const activeTab = updatedTabs[updatedTabs.length - 1] || homeTab.value;

    await switchRouteByTab(activeTab);
    update();
  }

  /**
   * Switch route by tab
   */
  async function switchRouteByTab(tab: KuliTab) {
    const fail = await router.push(tab.fullPath);

    if (!fail) {
      setActiveTabId(tab.id);
    }
  }

  /**
   * Clear left tabs
   */
  async function clearLeftTabs(tabId: string) {
    const tabIds = tabs.value.map((tab) => tab.id);
    const index = tabIds.indexOf(tabId);
    if (index === -1) {
      return;
    }

    const excludes = tabIds.slice(index);
    await clearTabs(excludes);
  }

  /**
   * Clear right tabs
   */
  async function clearRightTabs(tabId: string) {
    const tabIds = tabs.value.map((tab) => tab.id);
    const index = tabIds.indexOf(tabId);
    if (index === -1) {
      return;
    }

    const excludes = tabIds.slice(0, index + 1);
    await clearTabs(excludes);
  }

  /**
   * Set new label of tab
   */
  function setTabLabel(
    { label, tabId }:
    { label: string; tabId?: string },
  ) {
    const id = tabId || activeTabId.value;

    const tab = tabs.value.find((item) => item.id === id);
    if (!tab) {
      return;
    }

    tab.oldLabel = tab.label;
    tab.newLabel = label;
  }

  /**
   * Reset tab label
   */
  function resetTabLabel(tabId?: string) {
    const id = tabId || activeTabId.value;

    const tab = tabs.value.find((item) => item.id === id);
    if (!tab) {
      return;
    }

    tab.newLabel = undefined;
  }

  /**
   * Is tab retain
   */
  function isTabRetain(tabId: string) {
    if (tabId === homeTab.value?.id) {
      return true;
    }

    const fixedTabIds = getFixedTabIds(tabs.value);

    return fixedTabIds.includes(tabId);
  }

  /** Update tabs by locale */
  function updateTabsByLocale() {
    tabs.value = updateTabsByI18nKey(tabs.value);

    if (homeTab.value) {
      homeTab.value = updateTabByI18nKey(homeTab.value);
    }
  }

  /** Cache tabs */
  function cacheTabs() {
    if (!themeStore.tab.cache) {
      return;
    }

    tabLocalStorage.value = tabs.value;
  }

  // cache tabs when page is closed or refreshed
  useEventListener(window, 'beforeunload', () => {
    cacheTabs();
  });

  return {
    tabs: allTabs,
    activeTabId,
    initHomeTab,
    initTabStore,
    addTab,
    removeTab,
    removeActiveTab,
    removeTabByRouteName,
    clearTabs,
    clearLeftTabs,
    clearRightTabs,
    switchRouteByTab,
    setTabLabel,
    resetTabLabel,
    isTabRetain,
    updateTabsByLocale,
  };
});
