import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT } from '~~/router/router.entity';

const processingRoute: RouteRecordRaw = {
  path: '/item-info',
  name: '/item-info-root',
  component: LAYOUT_DEFAULT,
  meta: {
    localIcon: 'users-black',
    i18nKey: 'routes.item-info',
    title: 'item-info',
    hideInMenu: false,
  },
  children: [
    {
      path: '',
      name: '/item-info',
      component: () => import('~~/pages/item-info.vue'),
      meta: {
        localIcon: 'users-black',
        i18nKey: 'item',
        title: 'item',
        hideInMenu: true,
      },
    },
  ],
};

export default processingRoute;
