import type { DocumentReference, Timestamp } from 'firebase/firestore';

import type { SkyFlavour, SkyFlavourCodeProperties } from './flavour.entity';

export enum PRODUCT_STATUS {
  BOOKED = 'BOOKED', // 10
  CLOSED = 'CLOSED', // 7
  EXTERN = 'EXTERN', // 6
  FINEGRIT = 'FINEGRIT', // 12
  MERGED = 'MERGED', // 3
  MISSING = 'MISSING', // 9
  NEW = 'NEW', // 0
  PROCESSED = 'PROCESSED', // 5
  PROCESSING = 'PROCESSING', // 4
  REMOVED = 'REMOVED', // 11
  RETOUR = 'RETOUR', // 2
  RETOUR_AFTER_RETURN = 'RETOUR_AFTER_RETURN', // 8
  SOLD = 'SOLD', // 1
}

export const ACTIVE_STATUSES: Array<PRODUCT_STATUS> = [
  PRODUCT_STATUS.NEW,
  PRODUCT_STATUS.PROCESSING,
  PRODUCT_STATUS.BOOKED,
];

export interface SkyProduct {
  id: string;
  handledNumber: number;
  parentRef: DocumentReference;
  status: PRODUCT_STATUS;
  initialGrams: number;
  createdAt: Timestamp;
  processedAt: Timestamp;
  size: SkyProductSize;
  handledBy: DocumentReference | null;
  flavourRef: DocumentReference;
}
export interface SkyProductWithDetails extends SkyProduct {
  flavourDetails: SkyFlavour;
}

export interface SkyParentProduct {
  id: string;
  bags: number;
  category: string;
  createdAt: Timestamp;
  processedAt: Timestamp;
  flavourRef: DocumentReference;
  handledBy: DocumentReference | null;
  handledNumber: number;
  initialGrams: number;
  isVisible: boolean;
  notes: string;
  priceBuying: number;
  priceSell: number;
  restGrams: number;
  scancode: number;
  status: PRODUCT_STATUS;
}

export interface FirestoreStockReport extends SkyParentProduct {
  id: string;
  childItems?: Array<SkyProduct>;
  flavourLabel?: string;
}

export interface SkyProductSize {
  id: string;
  kassaDesc: SkyFlavourCodeProperties;
  i18nKey: string;
}

export interface SkyStockOverview {
  parents: number;
  products: {
    [key: string]: number;
  };
}

export interface SkyCombineProduct {
  id: string;
  initialGrams: number;
  restGrams: number;
  status: PRODUCT_STATUS;
  createdAt: Timestamp;
  size: string;
}

export interface SkyCombineItem {
  product: SkyCombineProduct;
  flavour: SkyFlavour;
  isItemNotAvailable: boolean;
  isParent: boolean;
}
export interface ProductStockReportFilterInput {
  dateRange: [number, number];
  batchStatus: Array<string>;
  childStatus: Array<string>;
  allTimeDateChecked: boolean;
  flavours: Array<string>;
  size: Array<string>;
}
