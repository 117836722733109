import type { RouteLocation, Router } from 'vue-router';

import type { KuliTab, KuliTabRoute } from '~~/router/tab/tab.types';

import { useI18n } from '~~/locales';
import { router } from '~~/router';

/**
 * Get all tabs
 */
export function getAllTabs(
  { tabs, homeTab }: { homeTab?: KuliTab; tabs: Array<KuliTab> },
) {
  if (!homeTab) {
    return [];
  }

  const filterHomeTabs = tabs.filter((tab) => tab.id !== homeTab.id);

  const fixedTabs = filterHomeTabs
    .filter(isFixedTab)
    .sort((a, b) => a.fixedIndex! - b.fixedIndex!);

  const remainTabs = filterHomeTabs.filter((tab) => !isFixedTab(tab));

  const allTabs = [homeTab, ...fixedTabs, ...remainTabs];

  return updateTabsLabel(allTabs);
}

function isFixedTab(tab: KuliTab) {
  return tab.fixedIndex !== undefined && tab.fixedIndex !== null;
}

/**
 * Get tab id by route
 */
export function getTabIdByRoute(route: KuliTabRoute) {
  const { path, query = {}, meta } = route;

  let id = path;

  if (meta.multiTab) {
    const queryKeys = Object.keys(query).sort();

    const qs = queryKeys.map((key) => `${key}=${query[key]}`).join('&');

    id = `${path}?${qs}`;
  }

  return id;
}

/**
 * Get tab by route
 */
export function getTabByRoute(route: KuliTabRoute) {
  const { $t } = useI18n();

  const { name, path, fullPath = path, meta } = route;
  const {
    title,
    i18nKey,
    fixedIndexInTab,
  } = meta;

  // Get icon and localIcon from getRouteIcons function
  const { icon, localIcon } = getRouteIcons(route);

  const label = i18nKey ? $t(i18nKey) : title;

  const tab: KuliTab = {
    id: getTabIdByRoute(route),
    label,
    routeKey: name as string,
    routePath: path,
    fullPath,
    fixedIndex: fixedIndexInTab,
    icon,
    localIcon,
    i18nKey,
  };

  return tab;
}

/**
 * The vue router will automatically merge the metas of all matched items, and the icons here may be affected by other
 * matching items, so they need to be processed separately
 */
export function getRouteIcons(route: KuliTabRoute) {
  // Set default value for icon at the beginning
  let icon: string = route?.meta?.icon || import.meta.env.KULI_MENU_ICON;
  let localIcon: string = route?.meta?.localIcon || '';

  // Route.matched only appears when there are multiple matches,so check if route.matched exists
  if (route.matched) {
    // Find the meta of the current route from matched
    const currentRoute = route.matched.find((r) => r.name === route.name);
    // If icon exists in currentRoute.meta, it will overwrite the default value
    icon = currentRoute?.meta?.icon || icon;
    localIcon = currentRoute?.meta?.localIcon || localIcon;
  }

  return { icon, localIcon };
}

/**
 * Get default home tab
 */
export function getDefaultHomeTab(homeRouteObject: RouteLocation) {
  const homeRouteName = homeRouteObject.name as string;
  const { $t } = useI18n();

  const i18nLabel = $t(`routes.${homeRouteName.replace('/', '')}`);

  let homeTab: KuliTab = {
    id: homeRouteName,
    label: i18nLabel || homeRouteName,
    routeKey: homeRouteName,
    routePath: homeRouteName,
    fullPath: homeRouteName,
  };

  const routes = router.getRoutes();
  const homeRoute = routes.find((route) => route.name === homeRouteName);
  if (homeRoute) {
    homeTab = getTabByRoute(homeRoute);
  }

  return homeTab;
}

/**
 * Is tab in tabs
 */
export function isTabInTabs(
  { tabId, tabs }: { tabId: string; tabs: Array<KuliTab> },
) {
  return tabs.some((tab) => tab.id === tabId);
}

/**
 * Filter tabs by id
 */
export function filterTabsById(
  { tabId, tabs }: { tabId: string; tabs: Array<KuliTab> },
) {
  return tabs.filter((tab) => tab.id !== tabId);
}

/**
 * Filter tabs by ids
 */
export function filterTabsByIds(
  { tabIds, tabs }: { tabIds: Array<string>; tabs: Array<KuliTab> },
) {
  return tabs.filter((tab) => !tabIds.includes(tab.id));
}

/**
 * extract tabs by all routes
 */
export function filterTabsByAllRoutes(
  { router, tabs }:
  { router: Router; tabs: Array<KuliTab> },
) {
  const routes = router.getRoutes();

  const routeNames = routes.map((route) => route.name);

  return tabs.filter((tab) => routeNames.includes(tab.routeKey));
}

/**
 * Get fixed tabs
 */
export function getFixedTabs(tabs: Array<KuliTab>) {
  return tabs.filter((tab) => tab.fixedIndex !== undefined);
}

/**
 * Get fixed tab ids
 */
export function getFixedTabIds(tabs: Array<KuliTab>) {
  const fixedTabs = getFixedTabs(tabs);

  return fixedTabs.map((tab) => tab.id);
}

/**
 * Update tabs label
 */
function updateTabsLabel(tabs: Array<KuliTab>) {
  return tabs.map((tab) => ({
    ...tab,
    label: tab.newLabel || tab.oldLabel || tab.label,
  }));
}

/**
 * Update tab by i18n key
 */
export function updateTabByI18nKey(tab: KuliTab) {
  const { $t } = useI18n();
  const { i18nKey, label } = tab;

  return {
    ...tab,
    label: i18nKey ? $t(i18nKey) : label,
  };
}

/**
 * Update tabs by i18n key
 */
export function updateTabsByI18nKey(tabs: Array<KuliTab>) {
  return tabs.map((tab) => updateTabByI18nKey(tab));
}

/**
 * find tab by route name
 */
export function findTabByRouteName(
  { name, tabs }:
  { name: string; tabs: Array<KuliTab> },
) {
  const tabId = name;
  const multiTabId = `${name}?`;

  return tabs.find((tab) => tab.id === tabId || tab.id.startsWith(multiTabId));
}
