import type { Router } from 'vue-router';

import { createProgressGuard } from './guard.nprogress';
import { createRouteGuard } from './guard.route';
import { createDocumentTitleGuard } from './guard.title';

export function createRouterGuard(router: Router) {
  createProgressGuard(router);
  createRouteGuard(router);
  createDocumentTitleGuard(router);
}
