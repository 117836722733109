import type { RouteRecordRaw } from 'vue-router';

import { defineStore } from 'pinia';
import { computed, ref, shallowRef } from 'vue';

import { useAuthStore } from '~~/auth/auth.store';
import { useCoreStore } from '~~/core/core.store';
import { router } from '~~/router';
import { type KuliMenu, getLayoutMenusByAuthRoutes, getSelectedMenuKeyPathByKey, transformMenuToSearchMenus, updateLocaleOfLayoutMenus } from '~~/router/menu';
import { getBreadcrumbsByRoute } from '~~/router/menu/breadcrumb.util';
import { filterAuthRoutesByRoles, getCacheRouteNames, sortRoutesByOrder } from '~~/router/router.utils';
import { createStaticRoutes } from '~~/router/routes';
import { useTabStore } from '~~/router/tab/tab.store';
import { SETUP_STORE_ID } from '~~/store/store.entity';

export const useRouterStore = defineStore(SETUP_STORE_ID.ROUTER, () => {
  const coreStore = useCoreStore();
  const authStore = useAuthStore();
  const tabStore = useTabStore();

  const hasAuthRoutesAdded = ref(false);

  const hasConstantRoutesAdded = ref(false);

  /**
   * Auth route mode
   *
   * It recommends to use static mode in the development environment, and use dynamic mode in the production.
   */
  const authRouteMode = ref(import.meta.env.KULI_AUTH_ROUTE_MODE);

  /** Home route key */
  const routeHome = ref(import.meta.env.KULI_ROOT_PATH);

  const routeObject = computed(() =>
    router.resolve({ path: routeHome.value }),
  );

  /** auth routes */
  const authRoutes = shallowRef<Array<RouteRecordRaw>>([]);

  function addAuthRoutes(routes: Array<RouteRecordRaw>): void {
    const authRoutesMap = new Map(authRoutes.value.map((route) => [route.name, route]));

    routes.forEach((route) => {
      authRoutesMap.set(route.name, route);
    });

    authRoutes.value = Array.from(authRoutesMap.values());
  }

  const removeRouteFns: Array<() => void> = [];

  /* ------ Menus ------ */
  const menus = ref<Array<KuliMenu>>([]);
  const searchMenus = computed(() => transformMenuToSearchMenus(menus.value));

  /** Get layout menus */
  function getLayoutMenus(routes: Array<RouteRecordRaw>) {
    menus.value = getLayoutMenusByAuthRoutes(routes);
  }

  /** Update layout menus by locale */
  function updateLayoutMenusByLocale() {
    menus.value = updateLocaleOfLayoutMenus(menus.value);
  }

  /**
   * Get selected menu key path
   *
   * @param selectedKey Selected menu key
   */
  function getSelectedMenuKeyPath(selectedKey: string) {
    return getSelectedMenuKeyPathByKey({ selectedKey, menus: menus.value });
  }

  /** initialize constant route */
  async function initConstantRoutes() {
    if (hasConstantRoutesAdded.value) {
      return;
    }

    if (authRouteMode.value === 'static') {
      const { guestRoutes } = createStaticRoutes();

      addAuthRoutes(guestRoutes);
    } else {
      // TODO: enable this for dynamic mode route
      // const { data, error } = await fetchConstantRoutes();

      // if (!error) {
      //   addAuthRoutes(data);
      // }
    }

    handleAuthRoutes();

    hasConstantRoutesAdded.value = true;
  }

  /** Init auth route */
  async function initAuthRoutes() {
    if (authRouteMode.value === 'static') {
      await initStaticAuthRoute();
    } else {
      await initDynamicAuthRoute();
    }

    tabStore.initHomeTab();
  }

  /** Init static auth route */
  async function initStaticAuthRoute() {
    const { authRoutes: staticAuthRoutes } = createStaticRoutes();

    if (authStore.isRootUser) {
      addAuthRoutes(staticAuthRoutes);
    } else {
      const filteredAuthRoutes = filterAuthRoutesByRoles({
        routes: staticAuthRoutes,
        roles: authStore.currentUser?.roles ?? [],
      });

      addAuthRoutes(filteredAuthRoutes);
    }

    handleAuthRoutes();

    hasAuthRoutesAdded.value = true;
  }

  /** Init dynamic auth route */
  async function initDynamicAuthRoute() {
    // TODO: implement this when backend route is ready
    // const { data, error } = await fetchGetUserRoutes();

    // if (!error) {
    //   const { routes, home } = data;

    //   addAuthRoutes(routes);

    //   handleAuthRoutes();

    //   setRouteHome(home);

    //   handleUpdateRootRouteRedirect(home);

    //   setIsInitAuthRoute(true);
  }

  /** handle auth routes */
  function handleAuthRoutes() {
    const sortRoutes = sortRoutesByOrder(authRoutes.value);

    resetAllRoutes();

    addRoutesToRouter(sortRoutes);

    getLayoutMenus(sortRoutes);

    getCacheRoutes(sortRoutes);
  }

  /** Reset all routes */
  function resetAllRoutes() {
    removeRouteFns.forEach((fn) => {
      fn();
    });
    removeRouteFns.length = 0;
  }

  /**
   * Add routes to vue router
   *
   * @param routes Vue routes
   */
  function addRoutesToRouter(routes: Array<RouteRecordRaw>) {
    routes.forEach((route) => {
      const removeFn = router.addRoute(route);
      addRemoveRouteFn(removeFn);
    });
  }

  /**
   * Add remove route fn
   */
  function addRemoveRouteFn(fn: () => void) {
    removeRouteFns.push(fn);
  }

  /* ------ Cache routes ------ */

  /** Cache routes */
  const cacheRoutes = ref<Array<string>>([]);

  /** All cache routes */
  const allCacheRoutes = shallowRef<Array<string>>([]);

  /**
   * Get cache routes
   */
  function getCacheRoutes(routes: Array<RouteRecordRaw>) {
    const alls = getCacheRouteNames(routes);

    cacheRoutes.value = alls;
    allCacheRoutes.value = [...alls];
  }

  /**
   * Re-cache routes by route key
   */
  async function reCacheRoutesByKey(routeKey: string) {
    if (!isCachedRoute(routeKey)) {
      return;
    }

    removeCacheRoutes(routeKey);

    await coreStore.reloadPage();

    addCacheRoutes(routeKey);
  }

  /**
   * Remove cache routes
   */
  function removeCacheRoutes(routeKey: string) {
    const index = cacheRoutes.value.findIndex((item) => item === routeKey);

    if (index === -1) {
      return;
    }

    cacheRoutes.value.splice(index, 1);
  }

  /**
   * Is cached route
   *
   * @param routeKey
   */
  function isCachedRoute(routeKey: string) {
    return allCacheRoutes.value.includes(routeKey);
  }

  /**
   * Add cache routes
   */
  function addCacheRoutes(routeKey: string) {
    if (cacheRoutes.value.includes(routeKey)) {
      return;
    }

    cacheRoutes.value.push(routeKey);
  }

  /* ------ Breadcrumbs ------ */

  /** Layout breadcrumbs */
  const breadcrumbs = computed(() => {
    const route = router.currentRoute.value as unknown as RouteRecordRaw;

    return getBreadcrumbsByRoute({
      route,
      menus: menus.value,
    });
  });

  return {
    hasAuthRoutesAdded,
    hasConstantRoutesAdded,
    cacheRoutes,
    initConstantRoutes,
    updateLayoutMenusByLocale,
    initAuthRoutes,
    getSelectedMenuKeyPath,
    routeHome,
    searchMenus,
    menus,
    reCacheRoutesByKey,
    routeObject,
    breadcrumbs,
  };
});
