import type { GlobalThemeOverrides } from 'naive-ui';

import { addColorAlpha, getColorByPaletteNumber, getColorPalette, getRgb } from '@vinicunca/color-palette';

import type { KuliTheme } from './theme.types';

import { DEFAULT_THEME, overrideThemeSettings } from './theme.entity';
import { themeLocalStorage, themeOverrideLocalStorage } from './theme.store';
import { themeVars } from './theme.vars';

const DARK_CLASS = 'dark';

/**
 * Initiate theme settings
 */
export function initThemeSettings() {
  const isProd = import.meta.env.PROD;

  // if it is development mode, the theme settings will not be cached, update `DEFAULT_THEME` in `src/designs/theme/theme.entity.ts` to update theme settings
  if (!isProd) {
    return DEFAULT_THEME;
  }

  // if it is production mode, the theme settings will be cached in localStorage

  const settings = themeLocalStorage.value;

  // if we want to update theme settings when publishing a new version, please update `overrideThemeSettings` in `src/designs/theme/theme.entity.ts`
  const isOverride = themeOverrideLocalStorage.value === __BUILD_TIME__;

  if (!isOverride) {
    Object.assign(settings, overrideThemeSettings);
    themeOverrideLocalStorage.value = __BUILD_TIME__;
  }

  return settings;
}

/**
 * Create theme token
 */
export function createThemeToken(colors: KuliTheme['ThemeColor']) {
  const paletteColors = createThemePaletteColors(colors);

  const themeTokens: KuliTheme['ThemeToken'] = {
    boxShadow: {
      header: '0 1px 2px rgb(0, 21, 41, 0.08)',
      sidebar: '2px 0 8px 0 rgb(29, 35, 41, 0.05)',
      tab: '0 1px 2px rgb(0, 21, 41, 0.08)',
    },
    colors: {
      ...paletteColors,
      base_text: 'rgb(31, 31, 31)',
      container: 'rgb(255, 255, 255)',
      inverted: 'rgb(0, 20, 40)',
      layout: 'rgb(247, 250, 252)',
      nprogress: paletteColors.primary,
    },
  };

  const darkThemeTokens: KuliTheme['ThemeToken'] = {
    boxShadow: {
      ...themeTokens.boxShadow,
    },
    colors: {
      ...themeTokens.colors,
      base_text: 'rgb(224, 224, 224)',
      container: 'rgb(28, 28, 28)',
      layout: 'rgb(18, 18, 18)',
    },
  };

  return {
    darkThemeTokens,
    themeTokens,
  };
}

/**
 * Create theme palette colors
 */
function createThemePaletteColors(colors: KuliTheme['ThemeColor']) {
  const colorKeys = Object.keys(colors) as Array<KuliTheme['ThemeColorKey']>;
  const colorPaletteVar = {} as KuliTheme['ThemePaletteColor'];

  colorKeys.forEach((key) => {
    const { palettes, main } = getColorPalette(colors[key]);

    colorPaletteVar[key] = main.hex;

    palettes.forEach((item) => {
      colorPaletteVar[`${key}-${item.number}`] = item.hex;
    });
  });

  return colorPaletteVar;
}

/**
 * Get css var by tokens
 */
function getCssVarByTokens(tokens: KuliTheme['BaseToken']) {
  const styles: Array<string> = [];

  function removeVarPrefix(value: string) {
    return value.replace('var(', '').replace(')', '');
  }

  function removeRgbPrefix(value: string) {
    return value.replace('rgb(', '').replace(')', '');
  }

  for (const [key, tokenValues] of Object.entries(themeVars)) {
    for (const [tokenKey, tokenValue] of Object.entries(tokenValues)) {
      let cssVarsKey = removeVarPrefix(tokenValue);
      let cssValue = tokens[key][tokenKey];

      if (key === 'colors') {
        cssVarsKey = removeRgbPrefix(cssVarsKey);
        const { r, g, b } = getRgb(cssValue);
        cssValue = `${r} ${g} ${b}`;
      }

      styles.push(`${cssVarsKey}: ${cssValue}`);
    }
  }

  return styles.join(';');
}

/**
 * Add theme vars to html
 */
export function addThemeVarsToHtml(tokens: KuliTheme['BaseToken'], darkTokens: KuliTheme['BaseToken']) {
  const cssVarStr = getCssVarByTokens(tokens);
  const darkCssVarStr = getCssVarByTokens(darkTokens);

  const css = `
    html {
      ${cssVarStr}
    }
  `;

  const darkCss = `
    html.${DARK_CLASS} {
      ${darkCssVarStr}
    }
  `;

  const styleId = 'theme-vars';

  const style = document.querySelector(`#${styleId}`) || document.createElement('style');

  style.id = styleId;

  style.textContent = css + darkCss;

  document.head.appendChild(style);
}

/**
 * Toggle css dark mode
 */
export function toggleCssDarkMode(darkMode = false) {
  function addDarkClass() {
    document.documentElement.classList.add(DARK_CLASS);
  }

  function removeDarkClass() {
    document.documentElement.classList.remove(DARK_CLASS);
  }

  if (darkMode) {
    addDarkClass();
  } else {
    removeDarkClass();
  }
}

/**
 * ------------------ Naive theme ------------------
 */

type NaiveColorScene = '' | 'Active' | 'Hover' | 'Pressed' | 'Suppl';
type NaiveColorKey = `${KuliTheme['ThemeColorKey']}Color${NaiveColorScene}`;
type NaiveThemeColor = Partial<Record<NaiveColorKey, string>>;
interface NaiveColorAction {
  handler: (color: string) => string;
  scene: NaiveColorScene;
}

/**
 * Get naive theme colors
 */
function getNaiveThemeColors(colors: KuliTheme['ThemeColor']) {
  const colorActions: Array<NaiveColorAction> = [
    { handler: (color) => color, scene: '' },
    { handler: (color) => color, scene: 'Suppl' },
    { handler: (color) => getColorByPaletteNumber(color, 500), scene: 'Hover' },
    { handler: (color) => getColorByPaletteNumber(color, 700), scene: 'Pressed' },
    { handler: (color) => addColorAlpha(color, 0.1), scene: 'Active' },
  ];

  const themeColors: NaiveThemeColor = {};

  const colorEntries = Object.entries(colors) as Array<[KuliTheme['ThemeColorKey'], string]>;

  colorEntries.forEach((color) => {
    colorActions.forEach((action) => {
      const [colorType, colorValue] = color;
      const colorKey: NaiveColorKey = `${colorType}Color${action.scene}`;
      themeColors[colorKey] = action.handler(colorValue);
    });
  });

  return themeColors;
}

/**
 * Get naive theme
 */
export function getNaiveTheme(colors: KuliTheme['ThemeColor']) {
  const { primary: colorLoading } = colors;

  const theme: GlobalThemeOverrides = {
    Button: {
      fontWeight: '700',
      heightSmall: '40px',
      heightMedium: '40px',
    },
    LoadingBar: {
      colorLoading,
    },
    common: {
      ...getNaiveThemeColors(colors),
    },
  };

  return theme;
}
