import { locale } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nl';

import type { KuliI18n } from '~~/locales/locale.types';

import { getLocale } from './locale.store';

/**
 * Set dayjs locale
 */
export function setDayjsLocale(lang: KuliI18n['LocaleType'] = 'en') {
  const localMap = {
    en: 'en',
    nl: 'nl',
  } satisfies Record<KuliI18n['LocaleType'], string>;

  const lang_ = lang || getLocale.value || 'en';

  locale(localMap[lang_]);
}
