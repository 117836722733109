export enum QUERY_KEY {
  COMBINE_QR_CODE = 'combine-qr-code',
  FLAVOURS = 'flavours',
  FLAVOURS_DETAIL = 'flavours-detail',
  FLAVOURS_ORDER_BY_NAME = 'flavours-order-by-name',
  FLAVOURS_STOCK = 'flavours-stock',
  INPUT_QR_CODE = 'input-qr-code',
  PARENT_BY_FLAVOUR = 'parent-by-flavour',
  ROLES = 'roles',
  STOCK_REPORT = 'stock-report',
  USERS = 'users',
  VENDORS = 'vendors',
}
