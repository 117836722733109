import type { App } from 'vue';

import { createPinia } from 'pinia';

import { resetAllStore } from './plugins/store.reset';

export function setupStore(app: App) {
  const store = createPinia();

  store.use(resetAllStore);

  app.use(store);
}
