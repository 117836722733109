import type { App } from 'vue';

import { browserLocalPersistence, browserPopupRedirectResolver, connectAuthEmulator, debugErrorMap, getAuth, indexedDBLocalPersistence, prodErrorMap } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectFunctionsEmulator } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { VueFire, VueFireAuthWithDependencies } from 'vuefire';

import { firebaseApp, firebaseFunctions, firestoreInstance } from '~~/api/api.firebase';

export async function setupFirebase(vueApp: App<Element>) {
  vueApp.use(VueFire, {
    firebaseApp,
    modules: [
      VueFireAuthWithDependencies({
        dependencies: {
          errorMap: import.meta.env.PROD
            ? debugErrorMap
            : prodErrorMap,

          persistence: [
            indexedDBLocalPersistence,
            browserLocalPersistence,
          ],

          popupRedirectResolver: browserPopupRedirectResolver,
        },
      }),
    ],
  });

  if (import.meta.env.DEV && import.meta.env.MODE !== 'staging' && import.meta.env.MODE !== 'production') {
    connectEmulators();
  }
}

function connectEmulators() {
  const host = '127.0.0.1';
  const {
    auth: authEmulator,
    firestore: firestoreEmulator,
    functions: functionsEmulator,
    storage: storageEmulator,
  } = __FIREBASE_EMULATOR_CONFIGS__;

  connectAuthEmulator(
    getAuth(firebaseApp),
    `http://${host}:${authEmulator.port}`,
    {
      disableWarnings: true,
    },
  );

  connectFunctionsEmulator(firebaseFunctions, host, functionsEmulator.port);
  connectFirestoreEmulator(firestoreInstance, host, firestoreEmulator.port);
  connectStorageEmulator(getStorage(firebaseApp), host, storageEmulator.port);
}
