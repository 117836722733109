import { FlavourRepository } from '~~/api/repositories/modules/flavour.repository';
import { MasterActivityRepository } from '~~/api/repositories/modules/master-activity-log.repository';
import { ParentProductRepository } from '~~/api/repositories/modules/parent-product.repository';
import { ProductRepository } from '~~/api/repositories/modules/product.repository';
import { RoleRepository } from '~~/api/repositories/modules/role.repository';
import { SizeRepository } from '~~/api/repositories/modules/size.repository';
import { UserRepository } from '~~/api/repositories/modules/user.repository';
import { VendorRepository } from '~~/api/repositories/modules/vendor.repository';

export const flavourRepo = new FlavourRepository();
export const masterActivityRepo = new MasterActivityRepository();
export const productRepo = new ProductRepository();
export const parentProductRepo = new ParentProductRepository();
export const sizeRepo = new SizeRepository();
export const userRepo = new UserRepository();
export const vendorRepo = new VendorRepository();
export const roleRepo = new RoleRepository();
