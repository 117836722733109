import type { App } from 'vue';

import {
  createRouter,
  createWebHistory,
} from 'vue-router';

import { createRouterGuard } from './guard';
import { BUILTIN_ROUTES } from './routes/builtin.routes';

export const router = createRouter({
  history: createWebHistory(import.meta.env.KULI_BASE_URL),
  routes: BUILTIN_ROUTES,
  scrollBehavior: () => ({ left: 0, top: 0 }),
  strict: true,
});

/** Setup Vue Router */
export async function setupRouter(app: App) {
  app.use(router);

  createRouterGuard(router);

  await router.isReady();
}
