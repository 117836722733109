import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT } from '~~/router/router.entity';

const flavourRoute: RouteRecordRaw = {
  path: '/flavour',
  name: 'FlavourRoot',
  component: LAYOUT_DEFAULT,
  meta: {
    order: 5,
    title: 'flavour',
    localIcon: 'flavours-black',
    i18nKey: 'routes.flavours',
  },
  children: [
    {
      path: '',
      name: '/flavour',
      component: () => import('~~/pages/flavour.vue'),
      meta: {
        title: 'flavour',
        hideInMenu: true,
      },
    },
  ],
};

export default flavourRoute;
