import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT } from '~~/router/router.entity';

const processingRoute: RouteRecordRaw = {
  path: '/processing',
  name: '/processing-root',
  component: LAYOUT_DEFAULT,
  meta: {
    localIcon: 'processing-black',
    order: 2,
    i18nKey: 'routes.processing',
    title: 'processing',
  },
  children: [
    {
      path: '',
      name: '/processing',
      component: () => import('~~/pages/processing.vue'),
      meta: {
        localIcon: 'processing-black',
        i18nKey: 'routes.processing',
        title: 'Processing',
        hideInMenu: true,
      },
    },
  ],
};

export default processingRoute;
