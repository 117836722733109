import type { RouteRecordRaw } from 'vue-router';

import type { KuliMenu } from '~~/router/menu/menu.types';

import CoreSvgIcon from '~~/core/components/core-svg-icon.vue';
import { useSvgIconRender } from '~~/core/composables/use-svg-icon';
import { useI18n } from '~~/locales';

/**
 * Get layout menus by auth routes
 *
 */
export function getLayoutMenusByAuthRoutes(routes: Array<RouteRecordRaw>) {
  const menus: Array<KuliMenu> = [];

  routes.forEach((route) => {
    if (!route.meta?.hideInMenu) {
      const menu = getLayoutMenuByBaseRoute(route);

      if (route.children?.some((child) => !child.meta?.hideInMenu)) {
        menu.children = getLayoutMenusByAuthRoutes(route.children);
      }

      menus.push(menu);
    }
  });

  return menus;
}

/**
 * Get layout menu by route
 */
export function getLayoutMenuByBaseRoute(route: RouteRecordRaw) {
  const { SvgIconVNode } = useSvgIconRender(CoreSvgIcon);
  const { $t } = useI18n();

  const { path } = route;
  const { title, i18nKey, icon = import.meta.env.KULI_MENU_ICON, localIcon } = route.meta ?? {};

  const label = i18nKey ? $t(i18nKey) : title!;

  const routeKey = getRouteKey(route);

  const menu: KuliMenu = {
    key: routeKey,
    label,
    i18nKey,
    routeKey,
    routePath: path,
    icon: SvgIconVNode({ icon, localIcon, fontSize: 20 }),
  };

  return menu;
}

/**
 * There's a possibility that the route only has one children and that's children is the index of the parent route. We need to change the routeKey in the menu so in the sidebar it shows the correct path.
 */
function getRouteKey(route: RouteRecordRaw) {
  let routeKey = route.name as string;

  const [firstChild] = route.children ?? [];

  if (firstChild?.path === '') {
    routeKey = firstChild.name as string;
  }

  return routeKey;
}

/**
 * Update locale of global menus
 */
export function updateLocaleOfLayoutMenus(menus: Array<KuliMenu>) {
  const { $t } = useI18n();

  const result: Array<KuliMenu> = [];

  menus.forEach((menu) => {
    const { i18nKey, label, children } = menu;

    const newLabel = i18nKey ? $t(i18nKey) : label;

    const newMenu: KuliMenu = {
      ...menu,
      label: newLabel,
    };

    if (children?.length) {
      newMenu.children = updateLocaleOfLayoutMenus(children);
    }

    result.push(newMenu);
  });

  return result;
}

/**
 * Get selected menu key path
 */
export function getSelectedMenuKeyPathByKey(
  { menus, selectedKey }:
  { menus: Array<KuliMenu>; selectedKey: string },
) {
  const keyPath: Array<string> = [];

  menus.some((menu) => {
    const path = findMenuPath({
      targetKey: selectedKey,
      menu,
    });

    const find = Boolean(path?.length);

    if (find) {
      keyPath.push(...path!);
    }

    return find;
  });

  return keyPath;
}

/**
 * Find menu path
 */
function findMenuPath(
  { targetKey, menu }:
  { menu: KuliMenu; targetKey: string },
): Array<string> | null {
  const path: Array<string> = [];

  function dfs(item: KuliMenu): boolean {
    path.push(item.key);

    if (item.key === targetKey) {
      return true;
    }

    if (item.children) {
      for (const child of item.children) {
        if (dfs(child)) {
          return true;
        }
      }
    }

    path.pop();

    return false;
  }

  if (dfs(menu)) {
    return path;
  }

  return null;
}

/**
 * Transform menu to searchMenus
 */
export function transformMenuToSearchMenus(menus: Array<KuliMenu>, treeMap: Array<KuliMenu> = []) {
  if (menus && menus.length === 0) {
    return [];
  }
  return menus.reduce((acc, cur) => {
    if (!cur.children) {
      acc.push(cur);
    }
    if (cur.children && cur.children.length > 0) {
      transformMenuToSearchMenus(cur.children, treeMap);
    }
    return acc;
  }, treeMap);
}
