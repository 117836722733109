import type { AUTH_ROLE, AuthUser, CreateUserRequest, CreateUserResponse } from '@vinicunca/kuli-entity';
import type { StorageReference } from 'firebase/storage';

import { FIRESTORE_COLLECTION } from '@vinicunca/kuli-entity';
import { FirebaseError } from 'firebase/app';
import { getAuth, updatePassword, updateProfile } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';

import { doHttpsCall } from '~~/api/functions.query';
import { BaseRepository } from '~~/api/repositories/repository-factory';

export interface FormFieldsAuthUser extends Omit<AuthUser, 'id'> {
  roles: Array<AUTH_ROLE>;
  password: string;
  confirmPassword: string;
}

export interface FormFieldsChangePassword {
  currentPassword: string;
  setNewPassword: string;
  confirmNewPassword: string;
}

export class UserRepository extends BaseRepository<AuthUser> {
  constructor() {
    super(FIRESTORE_COLLECTION.USERS);
  }

  getProfile(uid: string): Promise<AuthUser | null> {
    return this.getDocument(uid);
  }

  getAllUsers() {
    return this.executeQuery(this.collectionRef);
  }

  async addUser(formFields: FormFieldsAuthUser) {
    try {
      const result = await doHttpsCall<CreateUserRequest, CreateUserResponse>({
        data: {
          email: formFields.email,
          firstName: formFields.firstName,
          lastName: formFields.lastName,
          photoURL: formFields.photoURL,
          roles: formFields.roles,
          password: formFields.password,
        },
        fnName: 'createNewUser',
      });

      return result.uid;
    } catch (error) {
      if (error instanceof FirebaseError) {
        window.$message?.error(`${error.code}: ${error.message}`);
      } else {
        window.$message?.error(`Error creating user: ${error}`);
        throw error;
      }
    }
  }

  async editUserRoles(uid: string, roles: Array<AUTH_ROLE>) {
    return await this.updateDocument({ id: uid, data: { roles } });
  }

  async editUserAvatar(uid: string, photoURL: string) {
    return await this.updateDocument({ id: uid, data: { photoURL } });
  }

  async changePassword(formFields: FormFieldsChangePassword) {
    if (!formFields) {
      return;
    }

    const { setNewPassword: newPassword } = formFields;

    const user = getAuth()?.currentUser;

    updatePassword(user!, newPassword)
      .then(() => {
        window.$message?.success('password updated');
      }).catch((error) => {
        if (error instanceof FirebaseError) {
          window.$message?.error(`${error.code}: ${error.message}`);
        } else {
          window.$message?.error(`Error changing password: ${error}`);
        }
      });
  }

  async uploadAvatar(dataURL: string) {
    const storage = getStorage();
    const metadata = {
      contentType: 'image/jpeg',
    };
    const storageRef = ref(storage, `user-avatars/${Date.now()}.jpg`);
    const uploadTask = uploadString(storageRef, dataURL, 'data_url', metadata);
    const user = getAuth()?.currentUser;

    try {
      const snapshot: { ref: StorageReference } = await uploadTask;
      window.$message?.success('Upload completed successfully');

      const downloadURL = await getDownloadURL(snapshot.ref);
      await updateProfile(user!, { photoURL: downloadURL });
      this.editUserAvatar(user?.uid ?? '', downloadURL);
    } catch (error: any) {
      switch (error.code) {
        case 'storage/unauthorized':
          window.$message?.error(`Unauthorized: ${error.message}`);
          break;
        case 'storage/canceled':
          window.$message?.error(`Upload Canceled: ${error.message}`);
          break;
        case 'storage/unknown':
          window.$message?.error(`Unknown Error: ${error.message}`);
          break;
        default:
          window.$message?.error(`Error: ${error.message}`);
          break;
      }
    }
  }
}
