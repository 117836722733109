import { AUTH_ROLE, type AuthUser } from '@vinicunca/kuli-entity';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useCurrentUser, useFirebaseAuth } from 'vuefire';

import { userRepo } from '~~/api/repositories';
import { useProductStore } from '~~/modules/product/product.store';
import { router } from '~~/router';
import { useNavigation } from '~~/router/composables/use-navigation';
import { PAGES } from '~~/router/router.entity';
import { useRouterStore } from '~~/router/router.store';
import { SETUP_STORE_ID } from '~~/store/store.entity';

export const useAuthStore = defineStore(SETUP_STORE_ID.AUTH, () => {
  const firebaseAuth = useFirebaseAuth()!;
  const fbUser = useCurrentUser();

  const productStore = useProductStore();
  const routerStore = useRouterStore();

  const currentUser = ref<AuthUser | null>(null);

  const isRootUser = computed(
    () => currentUser.value?.roles?.includes(AUTH_ROLE.ROOT),
  );

  const isAdminOrRoot = computed(
    () => currentUser.value?.roles!.some((role) => [AUTH_ROLE.ADMIN, AUTH_ROLE.ROOT].includes(role)),
  );

  async function populateCurrentUser() {
    // User is not logged in yet
    if (!fbUser.value) {
      return null;
    }

    const { email, photoURL, uid } = fbUser.value;

    const userData = await userRepo.getProfile(uid);

    currentUser.value = {
      ...userData,
      email: email!,
      photoURL: photoURL!,
      id: uid,
    };
  }

  const { redirectFromLogin } = useNavigation(false);

  async function loginUser(
    { email, password, redirect = true }:
    { email: string; password: string; redirect?: boolean },
  ): Promise<AuthUser | null> {
    try {
      await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password,
      );

      await populateCurrentUser();

      /**
       * We need to populate the sizes because it's being used across the app.
       */
      await productStore.initSizes();

      await routerStore.initAuthRoutes();

      if (redirect) {
        await redirectFromLogin();
      }

      return currentUser.value;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function logout() {
    await signOut(firebaseAuth);
    currentUser.value = null;
    await router.replace(PAGES.CORE_LOGIN);
    await fetchAllUsers();
  }

  const userSelectionList = ref<Array<AuthUser>>([]);

  async function fetchAllUsers() {
    const userList: Array<AuthUser> = [];

    const users = await userRepo.getAllUsers();

    users.forEach((user) => {
      if (user.roles?.includes(AUTH_ROLE.ADMIN)) {
        const { firstName, ...rest } = user;
        userList.push({
          ...rest,
          firstName: firstName?.slice(0, 2),
        });
      }
    });

    userList.push({
      firstName: 'dev',
      roles: [AUTH_ROLE.ROOT],
    } as AuthUser);

    userSelectionList.value = userList;
  }

  return {
    currentUser,
    isRootUser,
    isAdminOrRoot,
    populateCurrentUser,
    loginUser,
    logout,
    fetchAllUsers,
    userSelectionList,
  };
});
