import type { SkyRole } from '@vinicunca/kuli-entity';

import { FIRESTORE_COLLECTION } from '@vinicunca/kuli-entity';

import type { FirestoreData } from '~~/api/repositories/repository.types';

import { BaseRepository } from '~~/api/repositories/repository-factory';

export class RoleRepository extends BaseRepository<FirestoreData<SkyRole>> {
  constructor() {
    super(FIRESTORE_COLLECTION.ROLES);
  }

  getAllRolesLabels() {
    return this.executeQuery(this.collectionRef);
  }
}
