import type { PiniaPluginContext } from 'pinia';

import { clone } from '@vinicunca/perkakas';

import { SETUP_STORE_ID } from '~~/store/store.entity';

/**
 * Since all the stores are written in setup syntax,
 * we need a plugin to be able to reset the state of each store.
 *
 * For examples: `authStore.$reset()`
 */
export function resetAllStore(context: PiniaPluginContext) {
  const setupSyntaxIds = Object.values(SETUP_STORE_ID) as Array<string>;

  if (setupSyntaxIds.includes(context.store.$id)) {
    const { $state } = context.store;

    const defaultState = clone($state);

    context.store.$reset = () => {
      context.store.$patch(defaultState);
    };
  }
}
