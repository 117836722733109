import type { KuliTheme } from './theme.types';

function createColorPaletteVars() {
  const colors: Array<KuliTheme['ThemeColorKey']> = ['primary', 'info', 'success', 'warning', 'error'];
  const colorPaletteNumbers: Array<KuliTheme['ColorPaletteNumber']> = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];

  const colorPaletteVar = {} as KuliTheme['ThemePaletteColor'];

  colors.forEach((color) => {
    colorPaletteVar[color] = `rgb(var(--${color}-color))`;
    colorPaletteNumbers.forEach((number) => {
      colorPaletteVar[`${color}-${number}`] = `rgb(var(--${color}-${number}-color))`;
    });
  });

  return colorPaletteVar;
}

const colorPaletteVars = createColorPaletteVars();

export const themeVars: KuliTheme['ThemeToken'] = {
  boxShadow: {
    header: 'var(--header-box-shadow)',
    sidebar: 'var(--sidebar-box-shadow)',
    tab: 'var(--tab-box-shadow)',
  },

  colors: {
    ...colorPaletteVars,
    base_text: 'rgb(var(--base-text-color))',
    container: 'rgb(var(--container-bg-color))',
    inverted: 'rgb(var(--inverted-bg-color))',
    layout: 'rgb(var(--layout-bg-color))',
    nprogress: 'rgb(var(--nprogress-color))',
  },
};
