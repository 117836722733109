import { set } from '@vinicunca/perkakas';

import type { KuliI18n } from './locale.types';

export const loadLocalePool: Array<KuliI18n['LocaleType']> = [];

export function setHtmlPageLang(locale: KuliI18n['LocaleType']) {
  document.querySelector('html')?.setAttribute('lang', locale);
};

export function setLoadLocalePool(cb: (loadLocalePool: Array<KuliI18n['LocaleType']>) => void) {
  cb(loadLocalePool);
};

export function genMessage(langs: Record<string, Record<string, any>>, prefix = 'lang') {
  let obj: Record<string, any> = {};

  Object.keys(langs).forEach((key) => {
    const langFileModule = langs[key].default;
    let fileName = key.replace(`./${prefix}/`, '').replace(/^\.\//, '');
    const lastIndex = fileName.lastIndexOf('.');
    fileName = fileName.substring(0, lastIndex);
    const keyList = fileName.split('/');
    const moduleName = keyList.shift();
    const objKey = keyList.join('.');

    if (moduleName) {
      if (objKey) {
        obj = set(obj, moduleName, obj[moduleName] || {});
        obj = set(obj[moduleName], objKey, langFileModule);
      } else {
        obj = set(obj, moduleName, langFileModule || {});
      }
    }
  });
  return obj;
};
