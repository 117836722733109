import type { NDateLocale, NLocale } from 'naive-ui';

import { dateEnGB, dateNlNL, enGB, nlNL } from 'naive-ui';

import type { KuliI18n } from './locale.types';

export const naiveLocales: Record<KuliI18n['LocaleType'], NLocale> = {
  en: enGB,
  nl: nlNL,
};

export const naiveDateLocales: Record<KuliI18n['LocaleType'], NDateLocale> = {
  en: dateEnGB,
  nl: dateNlNL,
};
