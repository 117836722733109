import type { FirebaseOptions } from 'firebase/app';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const { KULI_FIREBASE_CONFIG } = import.meta.env;

const firebaseConfig = JSON.parse(KULI_FIREBASE_CONFIG) as FirebaseOptions;

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseFunctions = getFunctions(firebaseApp, 'europe-west1');

export const firestoreInstance = getFirestore(firebaseApp);
